.loggedInLabel {
  font-size: 0.6em;
}

.loggedInUser {
  font-size: 0.6em;
}

.logoutButton {
  border: none;
  background: none;
  color: blue;
  text-decoration: underline;
  font-size: 0.6em;
}
