.queueCount {
  font-size: 5em;
}

.queue {
  margin-top: 1em;
}

.ticketBot {
  text-align: center;
}
